import { useContext } from "react";
import PropTypes from "prop-types";

import { CompanyContext } from "../CompanyList/CompanyContext";
import BaseLinkWrapper from "./BaseLinkWrapper";

export const BaseDotNumber = ({ displayName = "#", ...props }) => {
	const { company } = useContext(CompanyContext);
	const dotNumber = company.fields.dotNumber;
	if (!dotNumber) return null;

	return (
		<BaseLinkWrapper
			{...props}
		>{`${displayName} ${dotNumber}`}</BaseLinkWrapper>
	);
};

BaseDotNumber.propTypes = {
	displayName: PropTypes.string,
};
